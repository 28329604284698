import React, { useState } from "react";
import "./FAQ.css";
import useIntersectionObserver from "../customHooks/useIntersectionObserver";
const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const faqData = [
    {
      question: "What is AgentSearch?",
      answer:
        "AgentSearch is an Agentic AI-powered tool for efficiently searching unstructured and semi-structured enterprise data.",
    },
    {
      question: "How does it work?",
      answer:
        "It combines AI with retrieval-based systems to provide contextually relevant results for enterprise queries",
    },
    {
      question: "What data formats are supported?",
      answer:
        "AgentSearch supports structured, unstructured, and semi-structured data from various sources like documents and databases",
    },
    {
      question: "Is it scalable for large enterprises?",
      answer:
        "Yes, AgentSearch is designed to scale seamlessly across large datasets and complex data infrastructures",
    },
    {
      question: "Does it ensure data compliance?",
      answer:
        "Yes, AgentSearch adheres to data privacy regulations like GDPR, ensuring secure and compliant searches",
    },
  ];

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  const [ref, isVisible] = useIntersectionObserver({ threshold: 0.1 });

  return (
    <section
      className={`first-accordion ${isVisible ? "section-animation" : ""}`}
      ref={ref}
      id="five"
    >
      <div className="container">
        <div className="section-wrapper">
          <div className="section-heading">
            <div className="heading-tag">
              <span>FAQ</span>
            </div>
            <div className="heading-description">
              <h2>Frequently Asked Questions for AgentSearch</h2>
              <p>
                Get quick answers about how AgentSearch enhances enterprise data
                search and ensures secure results
              </p>
            </div>
          </div>
          <div className="accordion">
            {faqData.map((item, index) => (
              <div className="accordion-item" key={index}>
                <button
                  id={`accordion-button-${index + 1}`}
                  aria-expanded={activeIndex === index ? "true" : "false"}
                  onClick={() => toggleAccordion(index)}
                >
                  <span className="accordion-title">{item.question}</span>
                  <span class="icon" aria-hidden="true"></span>
                </button>
                <div className="accordion-content">
                  <p>{item.answer}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQ;
